import React from 'react'

import Ausstattung from '../components/ausstattung'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
const Ausstattung_ = ({ data }) =>
  <Layout title="Ausstattung">
    <Ausstattung  {...data} />
  </Layout>

export default Ausstattung_

export const pageQuery = graphql`
{
  stuetzen: allAusstattungStuetzenYaml {
    edges {
      node {
        title
        subline
        linkToProject
        fields {
          image {
            file {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  }
  gelaender: allAusstattungGelaenderYaml {
    edges {
      node {
        title
        subline
        linkToProject
        fields {
          image {
            file {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  }
  fussboden: allAusstattungFussbodenYaml {
    edges {
      node {
        title
        subline
        linkToProject
        fields {
          image {
            file {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  }
  entwaesserung: allAusstattungEntwaesserungYaml {
    edges {
      node {
        title
        subline
        linkToProject
        fields {
          image {
            file {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  }
}

`

