import React, { Component } from 'react'

import { Icon } from './../leistungen/style'
import SubPage from '../subPage'

import SecondFold from './../home/SecondFold'
import { Flex, Box } from '@rebass/grid/emotion'
import { Heading, Text } from '@rebass/emotion'
import { css } from '@emotion/react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const c = css`
    hyphens: auto;
    width: 90%;
    max-width: 70rem;
    margin: 2.5rem auto 0 auto;

    em {
        font-style: normal;
        font-weight: 400;
        hyphens: manual;
        color: #4975ba;
        border-bottom: 1px solid #4975ba;
    }
`

const Icon1 = () =>
    <Icon>
        <span>
            <svg viewBox="0 10 216.996 216.995">
                <path
                    d="M0,199.396h60.077V43.964L0,76.674V199.396L0,199.396z M18.158,87.458l23.762-12.933v106.707H18.158V87.458L18.158,87.458z
                                    M156.919,43.964v155.433h60.076V76.674L156.919,43.964z M198.838,181.238h-23.762V74.531l23.762,12.933V181.238z M72.182,37.373
                                v162.023h72.632V37.373l-36.316-19.774L72.182,37.373z M126.656,181.238H90.34V48.16l18.157-9.886l18.158,9.886V181.238z"
                />
            </svg>
        </span>
    </Icon>
/*
const Icon2 = () =>
    <Icon>
        <span>
            <svg viewBox="0 0 192.333 192.333">
                <path
                    d="M189.833,174.75H2.5c-1.381,0-2.5-1.119-2.5-2.5v-10.667c0-1.381,1.119-2.5,2.5-2.5h3.042V90.417H2.5
                        c-1.381,0-2.5-1.119-2.5-2.5s1.119-2.5,2.5-2.5h49.727V20.083c0-1.381,1.119-2.5,2.5-2.5h82.231c1.381,0,2.5,1.119,2.5,2.5v65.333
                        h50.375c1.381,0,2.5,1.119,2.5,2.5s-1.119,2.5-2.5,2.5h-2.958v68.667h2.958c1.381,0,2.5,1.119,2.5,2.5v10.667
                        C192.333,173.631,191.214,174.75,189.833,174.75z M5,169.75h182.333v-5.667H5V169.75z M173.375,159.083h8.5V90.417h-8.5V159.083z
                        M156.782,159.083h11.593V90.417h-11.593V159.083z M140.189,159.083h11.593V90.417h-11.593V159.083z M123.597,159.083h10.861V90.417
                        h-10.861V159.083z M107.004,159.083h11.593V90.417h-11.593V159.083z M90.412,159.083h11.592V90.417H90.412V159.083z M73.819,159.083
                        h11.593V90.417H73.819V159.083z M57.227,159.083h11.593V90.417H57.227V159.083z M40.634,159.083h11.592V90.417H40.634V159.083z
                        M24.042,159.083h11.593V90.417H24.042V159.083z M10.542,159.083h8.5V90.417h-8.5V159.083z M128.676,85.417h5.782V22.583H57.227
                        v62.833h5.783v-54.5c0-1.381,1.119-2.5,2.5-2.5h25.667c1.381,0,2.5,1.119,2.5,2.5v54.5h4.333v-54.5c0-1.381,1.119-2.5,2.5-2.5
                        h25.667c1.381,0,2.5,1.119,2.5,2.5V85.417z M103.009,85.388h20.667V33.417h-20.667V85.388z M68.009,85.388h20.667V33.417H68.009
                        V85.388z"
                />
            </svg>
        </span>
    </Icon>


const Icon3 = () =>
    <Icon>
        <span>
            <svg viewBox="0 0 512 512">
                <rect y="0.001" width="235.016" height="50.361" />
                <rect y="92.328" width="235.016" height="50.36" />
                <polygon points="0,184.656 0,235.016 71.344,235.016 163.672,235.016 235.016,235.016 235.016,184.656 		" />
                <rect x="461.638" y="0.001" width="50.361" height="235.016" />
                <rect x="369.31" y="0.001" width="50.36" height="235.016" />
                <polygon points="276.983,0.001 276.983,71.345 276.983,163.672 276.983,235.016 327.343,235.016 327.343,0.001 		" />
                <rect
                    x="276.983"
                    y="461.639"
                    width="235.016"
                    height="50.361"
                />
                <rect
                    x="276.983"
                    y="369.311"
                    width="235.016"
                    height="50.36"
                />
                <polygon points="440.656,276.984 348.327,276.984 276.983,276.984 276.983,327.344 512,327.344 512,276.984 		" />
                <rect y="276.984" width="50.361" height="235.016" />
                <rect x="92.328" y="276.984" width="50.36" height="235.016" />
                <polygon points="184.655,276.984 184.655,511.999 235.016,511.999 235.016,440.655 235.016,348.328 235.016,276.984 		" />
            </svg>
        </span>
    </Icon>

*/

export default class extends Component {
    render() {
        const { stuetzen, gelaender, fussboden, entwaesserung } = this.props

        return (
            <SubPage>
                <Box mx={[20, 50]} mb={[50, 100]}>
                    <Flex justifyContent="center">
                        <Box width={[1, 1 / 1.5]}>
                            <Text color="#6f7c82" fontSize={[18, 18, 18, 20]} fontWeight={600} textAlign="center">Unsere Balkon-Varianten</Text>
                            <Heading color="#292e31" fontSize={[30, 42, 46, 60]} fontWeight={600} textAlign="center">Ausstattung</Heading>
                        </Box>
                    </Flex>
                    <Box my={50}>
                        <Box my={30} mx="auto" css={[{ maxWidth: '90%' }]}>
                            <Icon1></Icon1>
                            <Heading my={20} textAlign="center" fontWeight={600} color="#4975ba" letterSpacing={2}>Vier Konstruktions-Varianten </Heading>
                            <Text css={c} color="#32335c" fontWeight={400} fontSize={[16, 16, 18, 22]} lineHeight={2} textAlign="center">
                                Abhängig von den lokalen Gegebenheiten gibt es unterschiedliche
                                Möglichkeiten für die Unterkonstruktion.
                            </Text>
                        </Box>

                        <Flex justifyContent="center" flexWrap="wrap">
                            {stuetzen.edges.map(({ node }, idx) =>
                                <Box key={idx} mx={0} px={15} my={10} width={[1, 0.5, 1 / 4]}>
                                    <GatsbyImage image={getImage(node.fields.image.file)} alt={node.title} />
                                    <Heading my={20} fontWeight={600} fontSize={[16, 16, 16, 20]} textAlign="center" color="#4975ba">{node.title}</Heading>
                                </Box>
                            )}
                        </Flex>

                    </Box>

                    <Box my={50}>
                        <Box my={30} mx="auto" css={{ maxWidth: '65%' }}>
                            <Icon1></Icon1>
                            <Heading my={20} textAlign="center" fontWeight={600} color="#4975ba" letterSpacing={2}>Geländer </Heading>
                            <Text css={c} color="#32335c" fontWeight={400} fontSize={[16, 16, 18, 22]} lineHeight={2} textAlign="center">

                            </Text>
                        </Box>

                        <Flex justifyContent="center" flexWrap="wrap">
                            {gelaender.edges.map(({ node }, idx) =>
                                <Box key={idx} mx={0} px={15} my={10} width={[1, 0.5, 1 / 4]}>
                                    <GatsbyImage image={getImage(node.fields.image.file)} alt={node.title} />
                                    <Heading my={20} fontWeight={600} fontSize={[16, 16, 16, 20]} textAlign="center" color="#4975ba">{node.title}</Heading>
                                </Box>
                            )}
                        </Flex>

                    </Box>

                    <Box my={50}>
                        <Box my={30} mx="auto" css={{ maxWidth: '65%' }}>
                            <Icon1></Icon1>
                            <Heading my={20} textAlign="center" fontWeight={600} color="#4975ba" letterSpacing={2}>Fußboden</Heading>
                            <Text css={c} color="#32335c" fontWeight={400} fontSize={[16, 16, 18, 22]} lineHeight={2} textAlign="center">

                            </Text>
                        </Box>


                        <Flex justifyContent="center" flexWrap="wrap">
                            {fussboden.edges.map(({ node }, idx) =>
                                <Box key={idx} mx={0} px={15} my={10} width={[1, 0.5, 1 / 4]}>
                                    <GatsbyImage image={getImage(node.fields.image.file)} alt={node.title} />
                                    <Heading my={20} fontWeight={600} fontSize={[16, 16, 16, 20]} textAlign="center" color="#4975ba">{node.title}</Heading>
                                </Box>
                            )}
                        </Flex>

                    </Box>

                    <Box my={50}>
                        <Box my={30}>
                            <Icon1></Icon1>
                            <Heading my={20} textAlign="center" fontWeight={600} color="#4975ba" letterSpacing={2}>Entwässerung Unterseite</Heading>
                            <Text css={c} color="#32335c" fontWeight={400} fontSize={[16, 16, 18, 22]} lineHeight={2} textAlign="center">

                            </Text>
                        </Box>

                        <Flex justifyContent="center" flexWrap="wrap">
                            {entwaesserung.edges.map(({ node }, idx) =>
                                <Box key={idx} mx={0} px={15} my={10} width={[1, 0.5, 1 / 4]}>
                                    <GatsbyImage image={getImage(node.fields.image.file)} alt={node.title} />
                                    <Heading my={20} fontWeight={600} fontSize={[16, 16, 16, 20]} textAlign="center" color="#4975ba">{node.title}</Heading>
                                </Box>
                            )}
                        </Flex>

                    </Box>
                </Box>
                <footer>
                    <SecondFold />
                </footer>
            </SubPage>
        )
    }
}
