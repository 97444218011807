import styled from "@emotion/styled";


export const Icon = styled('span')`
	width: 7rem;
	height: 7rem;
	border-radius: 7rem;

	@media screen and (min-width: 50em) {
		width: 9rem;
		height: 9rem;
		border-radius: 9rem;
	}

	background-color: rgba(73, 117, 186, 0.6);

	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;

	> span {
		width: 50%;
		height: 50%;
		display: inline-flex;
		line-height: 0;
	}

	> span > svg {
		width: 100%;
		height: 100%;
		circle,
		polygon,
		rect,
		path {
			fill: #ffffff;
		}
	}
`;
